declare var COMMIT_HASH: string;

export const environment = {
  production: true,
  apiURL: 'https://analytics.npp.net.ua/api/',
  stimulsoftURL: 'https://analytics.npp.net.ua/api/stimulsoft/',
  passportURL: 'https://passport.npp.net.ua',
  clientId: 'analytics-ua',
  clientSecret: 'zRXxzS43QggoLsa20Hmr8daPgoOyQ9DC',
  osmURL: 'https://analytics.npp.net.ua/osm/{z}/{x}/{y}.png',
  sentryZone: 'UA',
  sentryDsn: 'https://f4f2e4e273db4e919483cd03fc19557a@sentry.hrp.su/12',
  commitHash: COMMIT_HASH
};
